/* eslint-disable */
import { ChevronLeft, ChevronRight, ChevronsUpDownIcon, MoveRight } from 'lucide-react'
import hundred from '../../assets/SVGS/hundred.svg'
import rocket from '../../assets/SVGS/rocket.svg'
import saving from '../../assets/SVGS/saving.svg'
import noise from '../../assets/images/Rectangle.png'
import { Link, useSearchParams } from 'react-router-dom'
import { useCallback, useEffect, useRef, useState } from 'react'
import PopularFundsCarousel from './PopularFundsCarousel'
import BestPerformingTable from './BestPerformingTable'
import useMediaQuery from '../../hooks/useMediaQuery'
import CuretedBaskets from './CuretedBaskets'
import { RadioGroup, RadioGroupItem } from '../../Components/ui/radio-group'
import { Label } from '../../Components/ui/label'
import { Button } from '../ui/button'
import { FilterAltOutlined } from '@mui/icons-material'
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '../ui/sheet'
import { Separator } from '../ui/separator'
import { Checkbox } from '../ui/checkbox'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import { MutualFundsSelector, MutualFundsState } from '../../store/MFStore'
import { get, getMFData } from '../../http/restservice'
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '../../Components/ui/pagination'
import MFPagination from './MFPagination'
import { toCurrency } from '../../utils/CurrencyUtil'

const HybridFunds = ({ searchValue, mutualFundsData, totalPages, isLoading }) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const [searchParams, setSearchParams] = useSearchParams()
  const paramObject = Object.fromEntries(searchParams.entries())
  const tableRef = useRef()

  // const paramsObject = Object.fromEntries(searchParams.entries())

  // const [mutualFundsData, setMutualFundsData] = useState([])
  // const [totalPages, setTotalPages] = useState(0)

  // const mutualFundsList = useRecoilValueLoadable(MutualFundsSelector(paramsObject))

  const [showFilters, setShowFilters] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalItems, setTotalItems] = useState(0)
  const [loading, setLoading] = useState(true)

  const getSortParams = searchParams.get('sortBy')
  const getSortByValue =
    getSortParams === 'oneYearRet'
      ? '1Y'
      : getSortParams === 'threeYearRet'
      ? '3Y'
      : getSortParams === 'fiveYearRet'
      ? '5Y'
      : ''

  const [sortByYearBestFunds, setSortByYearBestFunds] = useState(getSortByValue || '3Y')

  const [sortByYearPopularFunds, setSortByYearPopularFunds] = useState('3Y')
  const [filterByAmcName, setFilterByAmcName] = useState(null)
  const [fliterByMinSipAmount, setFliterByMinSipAmount] = useState(
    searchParams.get('minSIPAmount') || null,
  )
  const [filterByMinOneTimeAmount, setFilterByMinOneTimeAmount] = useState(
    searchParams.get('minOneTimeAmount') || null,
  )
  const [filterByRiskProfile, setFilterByRiskProfile] = useState(
    searchParams.get('riskProfile') || null,
  )

  const [equityType, setEquityType] = useState(searchParams.get('equityType') || null)
  const [isELSS, setIsELSS] = useState(searchParams.get('isELSS') || null)
  const [sortOrder, setSortOrder] = useState(searchParams.get('sortDir') || 'desc')
  const [activeTab, setActiveTab] = useState('sort-by')
  const [filterCount, setFilterCount] = useState(0)

  const observer = useRef()

  useEffect(() => {
    delete paramObject.assetClass
    delete paramObject.size

    setFilterCount(Object.keys(paramObject).length)
  }, [JSON.stringify(paramObject)])

  useEffect(() => {
    let params = {
      assetClass: 'Hybrid',
      page: page,
      size: pageSize,
    }

    if (searchValue) {
      params.search = searchValue
      params.page = 0
    }

    if (fliterByMinSipAmount) {
      params.minSIPAmount = fliterByMinSipAmount
    }

    if (filterByMinOneTimeAmount != 'All' && filterByMinOneTimeAmount != null) {
      params.minOneTimeAmount = filterByMinOneTimeAmount
    }

    if (filterByRiskProfile) {
      params.riskProfile = filterByRiskProfile
    }

    if (equityType) {
      params.equityType = equityType
    }

    if (isELSS) {
      params.isELSS = isELSS
    }

    if (sortByYearBestFunds) {
      params.sortBy =
        sortByYearBestFunds === '1Y'
          ? 'oneYearRet'
          : sortByYearBestFunds === '3Y'
          ? 'threeYearRet'
          : sortByYearBestFunds === '5Y'
          ? 'fiveYearRet'
          : ''
    }

    if (sortOrder) {
      params.sortDir = sortOrder
    }

    // if (sortByYearPopularFunds) {
    //   params.sortBy =
    //     sortByYearPopularFunds === '1Y'
    //       ? 'oneYearRet'
    //       : sortByYearPopularFunds === '3Y'
    //       ? 'threeYearRet'
    //       : sortByYearPopularFunds === '5Y'
    //       ? 'fiveYearRet'
    //       : ''
    // }

    // const fetchMFData = async () => {
    //   const response = await get('/mutualfundservice/api/mfData/allFunds', {}, params)
    //   setLoading(false)
    //   setMutualFundsData(response.funds)
    //   setTotalPages(response.totalPages)
    // }

    // fetchMFData()

    setSearchParams(params)
  }, [
    searchValue,
    page,
    pageSize,
    isELSS,
    equityType,
    fliterByMinSipAmount,
    filterByMinOneTimeAmount,
    filterByRiskProfile,
    sortByYearBestFunds,
    sortOrder,
  ])

  const clearAllFilter = () => {
    setSearchParams({
      assetClass: 'Hybrid',
      page: 0,
      size: 10,
      sortBy: 'threeYearRet',
      sortDir: 'desc',
    })
    // setSortByYearBestFunds('3Y')
    // setSortByYearPopularFunds('3Y')
    // setFilterByAmcName(null)
    // setFliterByMinSipAmount(null)
    // setFilterByMinOneTimeAmount(null)
    // setFilterByRiskProfile(null)
  }

  const sortByOptions = ['Ascending', 'Descending']
  const volatilityOptions = ['Default', 'Low', 'Medium', 'High']
  const minInvestAmount = ['All', 100, 1000, 10000, 50000]
  const riskProfileOptions = ['Default', 'Very High', 'High', 'Moderate', 'Low']
  const equityTypeOptions = ['Default', 'Large Cap', 'Mid Cap', 'Small Cap']
  const wantsElssFundsOptions = ['Yes', 'No']

  const handleMinInvestAmount = (amt, type) => {
    if (type === 'Tax') {
      setFilterByMinOneTimeAmount(null)
      setIsELSS(true)
    } else if (type === 'Top') {
      setSortOrder('desc')
      setIsELSS(null)
      setFilterByMinOneTimeAmount(null)
    } else {
      setFilterByMinOneTimeAmount(amt)
    }

    tableRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      {isDesktop ? (
        <div className='flex flex-col gap-y-12 mt-12'>
          <div className='flex justify-between gap-x-4'>
            <div
              className='border border-[#F5E4FF] flex-1 rounded-[20px] flex items-center gap-x-4 px-5 py-4 cursor-pointer'
              style={{
                background:
                  'radial-gradient(88.14% 158.58% at -1.64% 0%, rgba(185, 64, 255, 0.08) 0%, rgba(185, 64, 255, 0) 100%)',
              }}
              onClick={() => handleMinInvestAmount(100)}
            >
              <img src={hundred} alt='Start With 100' />
              <div className='w-full'>
                <div className='flex justify-between items-start'>
                  <h3 className='font-semibold text-xl'>Start with ₹ 100</h3>
                  <ChevronRight className='text-[#E1AEFF] h-4 w-4' />
                </div>
                <p className='text-sm font-medium text-[#575757]'>
                  Invest small, grow big with ₹ 100
                </p>
              </div>
            </div>
            <div
              className='border border-[#FFEDE4] flex-1 rounded-[20px] flex items-center gap-x-4 px-5 py-4 cursor-pointer'
              style={{
                background:
                  'radial-gradient(88.14% 158.58% at -1.64% 0%, rgba(246, 103, 0, 0.08) 0%, rgba(246, 103, 0, 0) 100%) ',
              }}
              onClick={() => handleMinInvestAmount(100, 'Top')}
            >
              <img src={rocket} alt='Top' />
              <div className='w-full'>
                <div className='flex justify-between items-start'>
                  <h3 className='font-semibold text-xl'>Top Performing Funds</h3>
                  <ChevronRight className='text-[#E1AEFF] h-4 w-4' />
                </div>
                <p className='text-sm font-medium text-[#575757]'>
                  Max returns with top-performers
                </p>
              </div>
            </div>
            {/* <div
              className='border border-[#FFE4FE] flex-1 rounded-[20px] flex items-center gap-x-4 px-5 py-4 cursor-pointer'
              style={{
                background:
                  'radial-gradient(88.14% 158.58% at -1.64% 0%, rgba(246, 0, 181, 0.08) 0%, rgba(246, 0, 181, 0) 100%) ',
              }}
              onClick={() => handleMinInvestAmount(100, 'Tax')}
            >
              <img src={saving} alt='Tax Saver' />
              <div className='w-full'>
                <div className='flex justify-between items-start'>
                  <h3 className='font-semibold text-xl'>Tax Saving</h3>
                  <ChevronRight className='text-[#E1AEFF] h-4 w-4' />
                </div>
                <p className='text-sm font-medium text-[#575757]'>
                  Save taxes, grow wealth with ELSS
                </p>
              </div>
            </div> */}
          </div>

          {/* <CuretedBaskets /> */}

          {/* <div className='flex flex-col gap-y-6'>
            <div className='flex justify-between'>
              <h2 className='text-xl font-semibold'>Popular Debt Funds</h2>
              <button
                className='flex items-center gap-x-1 text-primary-500 border-b-[2px] border-dashed border-primary-500 font-medium'
                onClick={() =>
                  setSortByYearPopularFunds(
                    sortByYearPopularFunds === '3Y'
                      ? '1Y'
                      : sortByYearPopularFunds === '1Y'
                      ? '2Y'
                      : '3Y',
                  )
                }
              >
                {sortByYearPopularFunds} <ChevronsUpDownIcon className='h-4 w-4' />
              </button>
            </div>
            <PopularFundsCarousel />
          </div> */}

          <div className='flex flex-col gap-y-6'>
            <div className='flex justify-between'>
              <h2 className='text-xl font-semibold'>Best Performing Hybrid Funds</h2>

              <div className='flex items-center gap-x-2'>
                <button
                  className='flex justify-center items-center gap-x-[2px] text-primary-500 bg-primary-50 pl-2.5 pr-1 py-1 rounded-full font-medium'
                  onClick={() => setShowFilters((prev) => !prev)}
                >
                  Filters
                  <FilterAltOutlined fontSize='16px' />
                  {filterCount > 0 && (
                    <span className='inline-flex justify-center items-center rounded-full text-xs text-white h-4 w-4 bg-error-600'>
                      {filterCount}
                    </span>
                  )}
                </button>

                <button
                  className='flex items-center gap-x-1 text-primary-500 border-b-[2px] border-dashed border-primary-500 font-medium'
                  onClick={() =>
                    setSortByYearBestFunds(
                      sortByYearBestFunds === '5Y'
                        ? '1Y'
                        : sortByYearBestFunds === '1Y'
                        ? '3Y'
                        : '5Y',
                    )
                  }
                >
                  {sortByYearBestFunds} <ChevronsUpDownIcon className='h-4 w-4' />
                </button>
              </div>
            </div>

            <div className='flex gap-x-6 w-full'>
              {showFilters && (
                <div className={`rounded-3xl flex flex-col gap-y-4 w-3/12`}>
                  <div className='border p-4 md:p-6 mx-4 lg:mx-0 rounded-3xl flex flex-col gap-y-4 sticky top-16 mb-6 lg:mb-0 lg:min-w-[216px]'>
                    <div className='flex justify-between items-center border-b pb-2'>
                      <h3 className='hidden lg:block font-semibold text-black-500 text-[20px] kerning-[20px] -tracking-[0.8px]'>
                        Filters
                      </h3>

                      <Button
                        variant='simple'
                        size='simple'
                        className='text-black-200 hover:text-black-300 font-semibold text-sm leading-4'
                        onClick={clearAllFilter}
                      >
                        CLEAR
                      </Button>
                    </div>
                    <div className='flex lg:flex-col justify-between px-4 lg:px-0 gap-y-3'>
                      {/* <div>
                      <h4 className='text-black-400 text-[14px] kerning-[20px] font-medium mb-2'>
                        Min SIP Amount
                      </h4>
                      <RadioGroup
                        value={fliterByMinSipAmount}
                        onValueChange={(value) => {
                          setFliterByMinSipAmount(+value === 0 ? null : value)
                        }}
                        className='flex flex-col gap-y-2'
                      >
                        <div className='flex items-center space-x-2'>
                          <RadioGroupItem value={null} id='f0' />
                          <Label htmlFor='f0'>All</Label>
                        </div>
                        <div className='flex items-center space-x-2'>
                          <RadioGroupItem value='2000' id='f1' />
                          <Label htmlFor='f1'>Under &#8377; 2,000</Label>
                        </div>
                        <div className='flex items-center space-x-2'>
                          <RadioGroupItem value='5000' id='f2' />
                          <Label htmlFor='f2'>Under &#8377; 5,000</Label>
                        </div>
                        <div className='flex items-center space-x-2'>
                          <RadioGroupItem value='10000' id='f3' />
                          <Label htmlFor='f3'>Under &#8377; 10,000</Label>
                        </div>
                        <div className='flex items-center space-x-2'>
                          <RadioGroupItem value='50000' id='f4' />
                          <Label htmlFor='f4'>Under &#8377; 50,000</Label>
                        </div>
                      </RadioGroup>
                    </div> */}

                      <div>
                        <h4 className='text-black-400 text-[14px] kerning-[20px] font-medium mb-2'>
                          Risk Profile
                        </h4>

                        <RadioGroup
                          value={filterByRiskProfile}
                          onValueChange={(value) => {
                            setFilterByRiskProfile(value)
                          }}
                          className='flex flex-col lg:flex-nowrap gap-y-2'
                        >
                          <div className='flex items-center space-x-2'>
                            <RadioGroupItem value={null} id='v0' />
                            <Label htmlFor='v0'>All</Label>
                          </div>
                          <div className='flex items-center space-x-2'>
                            <RadioGroupItem value='Very High' id='v1' />
                            <Label htmlFor='v1'>Very High</Label>
                          </div>
                          <div className='flex items-center space-x-2'>
                            <RadioGroupItem value='High' id='v1' />
                            <Label htmlFor='v1'>High</Label>
                          </div>
                          <div className='flex items-center space-x-2'>
                            <RadioGroupItem value='Moderate' id='v2' />
                            <Label htmlFor='v2'>Moderate</Label>
                          </div>
                          <div className='flex items-center space-x-2'>
                            <RadioGroupItem value='Low' id='v3' />
                            <Label htmlFor='v3'>Low</Label>
                          </div>
                        </RadioGroup>
                        {/* <div className='flex flex-col lg:flex-nowrap gap-y-2'>
                        <div className='flex items-center space-x-2'>
                          <Checkbox
                            id='v0'
                            checked={filterByRiskProfile.length === 0}
                            onCheckedChange={() => handleCheckboxChange('All')}
                          />
                          <Label htmlFor='v0'>All</Label>
                        </div>
                        <div className='flex items-center space-x-2'>
                          <Checkbox
                            id='v1'
                            checked={filterByRiskProfile.includes('Very High')}
                            onCheckedChange={() => handleCheckboxChange('Very High')}
                          />
                          <Label htmlFor='v1'>Very High</Label>
                        </div>
                        <div className='flex items-center space-x-2'>
                          <Checkbox
                            id='v1'
                            checked={filterByRiskProfile.includes('High')}
                            onCheckedChange={() => handleCheckboxChange('High')}
                          />
                          <Label htmlFor='v1'>High</Label>
                        </div>
                        <div className='flex items-center space-x-2'>
                          <Checkbox
                            id='v2'
                            checked={filterByRiskProfile.includes('Moderate')}
                            onCheckedChange={() => handleCheckboxChange('Moderate')}
                          />
                          <Label htmlFor='v2'>Moderate</Label>
                        </div>
                        <div className='flex items-center space-x-2'>
                          <Checkbox
                            id='v3'
                            checked={filterByRiskProfile.includes('Low')}
                            onCheckedChange={() => handleCheckboxChange('Low')}
                          />
                          <Label htmlFor='v3'>Low</Label>
                        </div>
                      </div> */}
                      </div>

                      <div>
                        <h4 className='text-black-400 text-[14px] kerning-[20px] font-medium mb-2'>
                          Min Investment Amount
                        </h4>
                        <RadioGroup
                          value={filterByMinOneTimeAmount}
                          onValueChange={(value) => {
                            setFilterByMinOneTimeAmount(+value === 0 ? null : value)
                          }}
                          className='flex flex-col gap-y-2'
                        >
                          {minInvestAmount.map((amount, i) => (
                            <>
                              {amount === 'All' ? (
                                <div className='flex items-center space-x-2'>
                                  <RadioGroupItem value={null} id='g0' />
                                  <Label htmlFor='g0'>All</Label>
                                </div>
                              ) : (
                                <div className='flex items-center space-x-2'>
                                  <RadioGroupItem value={amount} id={'amount' + i} />
                                  <Label htmlFor={'amount' + i}>Under ₹ {toCurrency(amount)}</Label>
                                </div>
                              )}
                            </>
                          ))}
                        </RadioGroup>
                      </div>

                      <div>
                        <h4 className='text-black-400 text-[14px] kerning-[20px] font-medium mb-2'>
                          Equity Type
                        </h4>

                        <RadioGroup
                          value={equityType}
                          onValueChange={(value) => {
                            setEquityType(value)
                          }}
                          className='flex flex-col lg:flex-nowrap gap-y-2'
                        >
                          <div className='flex items-center space-x-2'>
                            <RadioGroupItem value={null} id='v0' />
                            <Label htmlFor='v0'>All</Label>
                          </div>
                          <div className='flex items-center space-x-2'>
                            <RadioGroupItem value='Large' id='v1' />
                            <Label htmlFor='v1'>Large Cap</Label>
                          </div>
                          <div className='flex items-center space-x-2'>
                            <RadioGroupItem value='Mid' id='v1' />
                            <Label htmlFor='v1'>Mid Cap</Label>
                          </div>
                          <div className='flex items-center space-x-2'>
                            <RadioGroupItem value='Small' id='v2' />
                            <Label htmlFor='v2'>Small Cap</Label>
                          </div>
                        </RadioGroup>
                      </div>

                      {/* <div>
                        <h4 className='text-black-400 text-[14px] kerning-[20px] font-medium mb-2'>
                          ELSS Funds
                        </h4>

                        <RadioGroup
                          value={isELSS}
                          onValueChange={(value) => {
                            setIsELSS(value)
                          }}
                          className='flex flex-col lg:flex-nowrap gap-y-2'
                        >
                          <div className='flex items-center space-x-2'>
                            <RadioGroupItem value={true} id='v1' />
                            <Label htmlFor='v1'>Yes</Label>
                          </div>
                          <div className='flex items-center space-x-2'>
                            <RadioGroupItem value={null} id='v1' />
                            <Label htmlFor='v1'>No</Label>
                          </div>
                        </RadioGroup>
                      </div> */}
                    </div>
                  </div>
                </div>
              )}

              <div ref={tableRef} className={`${showFilters ? 'w-9/12' : 'w-full'}`}>
                <BestPerformingTable
                  funds={mutualFundsData}
                  sortByYearBestFunds={sortByYearBestFunds}
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                  isLoading={isLoading}
                  // lastPhotoRef={lastPhotoRef}
                />
              </div>
            </div>
            {mutualFundsData.length > 0 && (
              <MFPagination
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalItems={totalItems}
              />
            )}
          </div>
        </div>
      ) : (
        <div className='flex flex-col gap-y-12 mt-12'>
          <div className='flex gap-x-3 p-4'>
            <div
              className='border border-[#F5E4FF] flex-1 rounded-[20px] flex flex-col justify-between items-center gap-y-2 px-5 py-4 cursor-pointer'
              style={{
                background:
                  'radial-gradient(88.14% 158.58% at -1.64% 0%, rgba(185, 64, 255, 0.08) 0%, rgba(185, 64, 255, 0) 100%)',
              }}
              onClick={() => handleMinInvestAmount(100, 'Invest')}
            >
              <img className='h-12 w-12' src={hundred} alt='Start With 100' />
              <div className='w-full'>
                <div className='flex flex-col items-center'>
                  <p className='text-xs text-black-400 text-center'>Start with</p>
                  <p className='font-semibold text-sm'>₹ 100</p>
                </div>
              </div>
            </div>
            <div
              className='border border-[#FFEDE4] flex-1 rounded-[20px] flex flex-col justify-between items-center gap-y-2 px-5 py-4 cursor-pointer'
              style={{
                background:
                  'radial-gradient(88.14% 158.58% at -1.64% 0%, rgba(246, 103, 0, 0.08) 0%, rgba(246, 103, 0, 0) 100%) ',
              }}
              onClick={() => handleMinInvestAmount(100, 'Top')}
            >
              <img className='h-12 w-12' src={rocket} alt='Start With 100' />
              <div className='w-full'>
                <div className='flex flex-col items-center'>
                  <p className='text-sm font-semibold text-center'>High-return</p>
                  <p className='text-black-400 text-xs'>Funds</p>
                </div>
              </div>
            </div>
            {/* <div
              className='border border-[#FFE4FE] flex-1 rounded-[20px] flex flex-col justify-between items-center gap-y-2 px-5 py-4 cursor-pointer'
              style={{
                background:
                  'radial-gradient(88.14% 158.58% at -1.64% 0%, rgba(246, 0, 181, 0.08) 0%, rgba(246, 0, 181, 0) 100%) ',
              }}
              onClick={() => handleMinInvestAmount(100, 'Tax')}
            >
              <img className='h-12 w-12' src={saving} alt='Start With 100' />
              <div className='w-full'>
                <div className='flex flex-col items-center'>
                  <p className='text-sm font-semibold text-center'>Tax Saving</p>
                  <p className='text-black-400 text-xs text-center'>ELSS Funds</p>
                </div>
              </div>
            </div> */}
          </div>

          {/* <div className='flex flex-col gap-y-6 p-4'>
            <div className='flex justify-between'>
              <h2 className='text-xl font-semibold'>Popular Debt Funds</h2>
              <button className='flex items-center gap-x-1 text-primary-500 border-b-[2px] border-dashed border-primary-500 font-medium'>
                3Y <ChevronsUpDownIcon className='h-4 w-4' />
              </button>
            </div>
            <PopularFundsCarousel />
          </div> */}

          {/* <CuretedBaskets /> */}

          <div className='flex flex-col gap-y-6 p-4'>
            <div ref={tableRef} className='flex justify-between items-center'>
              <h2 className='text-lg font-semibold'>Best Performing Debt Funds</h2>
              <div className='flex items-center gap-x-4'>
                <Sheet>
                  <SheetTrigger>
                    <FilterAltOutlined className='text-primary-500' sx={{ fontSize: '20px' }} />
                  </SheetTrigger>
                  <SheetContent className='w-wull rounded-t-3xl p-0 min-h-[70vh]' side='bottom'>
                    <SheetHeader className='p-4'>
                      <div className='flex justify-between'>
                        <SheetTitle className='text-start text-base font-semibold font-DMSans tracking-[-4%] leading-6'>
                          Sort & Filter
                        </SheetTitle>
                        <Button
                          onClick={() => clearAllFilter()}
                          variant='simple'
                          size='simple'
                          className='text-black-200 text-xs'
                        >
                          CLEAR ALL
                        </Button>
                      </div>
                    </SheetHeader>

                    <div className='flex border-t h-[50vh]'>
                      <div className='w-1/3 border-r'>
                        {['sort-by', 'Risk Profile', 'Invest Amount'].map((tab) => (
                          <button
                            key={tab}
                            onClick={() => setActiveTab(tab)}
                            className={`w-full text-left px-4 py-3 text-sm border-b font-medium ${
                              activeTab === tab ? 'bg-primary-50 text-primary-500' : ''
                            }`}
                          >
                            {tab.charAt(0).toUpperCase() + tab.slice(1).replace('-', ' ')}
                          </button>
                        ))}
                      </div>

                      <div className='w-2/3 p-4 space-y-2'>
                        {activeTab === 'sort-by' &&
                          sortByOptions.map((option) => (
                            <button
                              onClick={() =>
                                setSortOrder((prev) => (prev === 'desc' ? 'asc' : 'desc'))
                              }
                              key={option}
                              className={`w-full text-left px-4 py-2 text-sm border-b rounded-none hover:bg-gray-50 ${
                                sortOrder === 'asc' && option === 'Ascending' && 'text-primary-500'
                              } ${
                                sortOrder === 'desc' &&
                                option === 'Descending' &&
                                'text-primary-500'
                              }`}
                            >
                              {option}
                            </button>
                          ))}
                        {/* {activeTab === 'volatility' &&
                          volatilityOptions.map((option) => (
                            <button
                              onClick={() => filterByRiskProfile(option)}
                              key={option}
                              className={`w-full text-left px-4 py-2 text-sm border-b rounded-none hover:bg-gray-50 ${
                                filterByRiskProfile === option && 'text-primary-500'
                              }`}
                            >
                              {option}
                            </button>
                          ))} */}
                        {activeTab === 'Risk Profile' &&
                          riskProfileOptions.map((option) => (
                            <button
                              onClick={() => setFilterByRiskProfile(option)}
                              key={option}
                              className={`w-full text-left px-4 py-2 text-sm border-b rounded-none hover:bg-gray-50 ${
                                (filterByRiskProfile === option ||
                                  (filterByRiskProfile === null && option === 'Default')) &&
                                'text-primary-500'
                              }`}
                            >
                              {option}
                            </button>
                          ))}

                        {/* {activeTab === 'Equity Type' &&
                          equityTypeOptions.map((option) => (
                            <button
                              onClick={() => setEquityType(option)}
                              key={option}
                              className={`w-full text-left px-4 py-2 text-sm border-b rounded-none hover:bg-gray-50 ${
                                (equityType === option ||
                                  (equityType === null && option === 'Default')) &&
                                'text-primary-500'
                              }`}
                            >
                              {option}
                            </button>
                          ))} */}

                        {activeTab === 'Invest Amount' &&
                          minInvestAmount.map((option) => (
                            <button
                              onClick={() => setFilterByMinOneTimeAmount(option)}
                              key={option}
                              className={`w-full text-left px-4 py-2 text-sm border-b rounded-none hover:bg-gray-50 ${
                                (filterByMinOneTimeAmount === option ||
                                  (filterByMinOneTimeAmount === null && option === 'All')) &&
                                'text-primary-500'
                              }`}
                            >
                              {option !== 'All' ? `Under ₹ ${toCurrency(option)}` : option}
                            </button>
                          ))}

                        {/* {activeTab === 'ELSS Funds' &&
                          wantsElssFundsOptions.map((option) => (
                            <button
                              onClick={() => setIsELSS(option)}
                              key={option}
                              className={`w-full text-left px-4 py-2 text-sm border-b rounded-none hover:bg-gray-50 ${
                                (isELSS === option || (isELSS === null && option === 'No')) &&
                                'text-primary-500'
                              }`}
                            >
                              {option}
                            </button>
                          ))} */}
                      </div>
                    </div>

                    <SheetClose>
                      <div className='p-4 absolute bottom-0 left-0 right-0'>
                        <Button
                          className='w-full bg-primary-500 hover:bg-primary-500 text-white'
                          size='lg'
                        >
                          Apply
                        </Button>
                      </div>
                    </SheetClose>
                  </SheetContent>
                </Sheet>

                <button
                  className='flex items-center gap-x-1 text-sm text-primary-500 border-b-[2px] border-dashed border-primary-500 font-medium'
                  onClick={() =>
                    setSortByYearBestFunds(
                      sortByYearBestFunds === '5Y'
                        ? '1Y'
                        : sortByYearBestFunds === '1Y'
                        ? '3Y'
                        : '5Y',
                    )
                  }
                >
                  {sortByYearBestFunds} <ChevronsUpDownIcon className='h-4 w-4' />
                </button>
              </div>
            </div>

            <BestPerformingTable
              funds={mutualFundsData}
              sortByYearBestFunds={sortByYearBestFunds}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              isLoading={isLoading}
            />

            {/* <BestPerformingTable funds={mutualFundsData} /> */}
            {mutualFundsData.length > 0 && (
              <MFPagination
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalItems={totalItems}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default HybridFunds
