// import Autoplay from 'embla-carousel-autoplay'
import { useRef, useState } from 'react'
import slider from '../../assets/Auth/slider.svg'
import graph from '../../assets/Auth/graph.svg'
import left_chevron from '../../assets/Auth/chevron_backward.svg'
import right_chevron from '../../assets/Auth/chevron_forward.svg'
import ontree from '../../assets/Auth/onboarding.png'
import Bank from '../../assets/Auth/Bank.png'
import people from '../../assets/Auth/people.png'

const slides = [
  {
    img: ontree,
    subtitle: 'Build wealth for financial milestones of your life',
    subtext:
      'From your next vacation to your retirement - build corpus for all your financial obligations',
    extraImg: slider,
  },
  {
    img: Bank,
    subtitle: 'Plan, Invest, & track All your money in one place',
    subtext: 'No more shuffling between Excel sheets & your demat account',
    extraImg: graph,
  },
  {
    img: people,
    subtitle: 'Get Professional Expertise for your Personal Finance',
    subtext: 'Create wealth with personalised strategies built by professionals',
    extraImg: null,
  },
]

export const AuthCarouselDesktop = () => {
  //   const plugin = React.useRef(Autoplay({ delay: 2000, stopOnInteraction: true }))
  const [currentSlide, setCurrentSlide] = useState(0)

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length)
  }

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length)
  }

  return (
    <main className='p-0'>
      <div className='max-w-[600px] rounded-[24px] bg-[#1B243B] flex flex-col items-center relative overflow-hidden'>
        <div
          className='flex w-full h-full transition-transform duration-500'
          style={{
            transform: `translateX(-${currentSlide * 100}%)`,
          }}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className='flex-shrink-0 w-full h-full flex flex-col items-center justify-center pt-[100px] mb-[100px]'
            >
              <div>
                <img src={slide.img} alt={slide.subtext} />
              </div>
              <div className='w-[404px] h-[112px]'>
                <p className='font-DMSans text-[24px] font-semibold text-center text-[#ECEEF4]'>
                  {slide.subtitle}
                </p>
                <p className='font-DMSans text-[14px] font-normal mt-[8px] text-center text-[rgba(168,177,203,1)]'>
                  {slide.subtext}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className='w-[192px] h-[32px] flex items-center gap-4 mb-[48px] justify-between'>
          <button
            className='w-[32px] h-[32px] flex items-center justify-center rounded-full bg-[#242f4e]'
            onClick={prevSlide}
          >
            <img src={left_chevron} alt='left-chevron' />
          </button>

          <div className='flex gap-2'>
            {slides.map((_, index) => (
              <button
                key={index}
                className={`w-[4px] h-[4px] rounded-full ${
                  currentSlide === index ? 'bg-[#F6F6F6] !w-[17px]' : 'bg-[#505668]'
                }`}
                onClick={() => setCurrentSlide(index)}
              ></button>
            ))}
          </div>

          <button
            className='w-[32px] h-[32px] flex items-center justify-center rounded-full bg-[#242f4e]'
            onClick={nextSlide}
          >
            <img src={right_chevron} alt='right-chevron' />
          </button>
        </div>
      </div>
    </main>
  )
}

export const AuthCarouselMobile = () => {
  //   const plugin = React.useRef(Autoplay({ delay: 2000, stopOnInteraction: true }))
  const [currentSlide, setCurrentSlide] = useState(0)
  const startX = useRef(0)
  const isDragging = useRef(false)

  const handleTouchStart = (e) => {
    isDragging.current = true
    startX.current = e.touches ? e.touches[0].clientX : e.clientX
  }

  const handleTouchMove = (e) => {
    if (!isDragging.current) return
    const currentX = e.touches ? e.touches[0].clientX : e.clientX
    const diff = startX.current - currentX

    if (Math.abs(diff) > 50) {
      if (diff > 0) {
        nextSlide()
      } else {
        prevSlide()
      }
      isDragging.current = false
    }
  }

  const handleTouchEnd = () => {
    isDragging.current = false
  }

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length)
  }

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length)
  }

  return (
    <main className='mx-auto'>
      <div
        className='w-screen pt-[91px] bg-[#1B243B] flex flex-col items-center justify-center relative overflow-x-hidden'
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onMouseDown={handleTouchStart}
        onMouseMove={handleTouchMove}
        onMouseUp={handleTouchEnd}
      >
        <div
          className='flex w-full transition-transform duration-500'
          style={{
            transform: `translateX(-${currentSlide * 100}%)`,
          }}
        >
          {slides.map((slide, index) => (
            <div key={index} className='flex-shrink-0 w-full h-full flex flex-col items-center'>
              <div>
                <img src={slide.img} alt={slide.subtext} />
              </div>
              <div className='w-[404px] h-[130px]'>
                <p className='font-DMSans text-[24px] font-semibold px-4 text-center text-[#ECEEF4]'>
                  {slide.subtitle}
                </p>
                <p className='font-DMSans text-[14px] font-normal px-12 text-center text-[rgba(168,177,203,1)]'>
                  {slide.subtext}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className='mt-[109px] w-[192px] h-[32px] flex items-center gap-4 justify-center z-[10]'>
          <div className='flex gap-2'>
            {slides.map((_, index) => (
              <button
                key={index}
                className={`w-[4px] h-[4px] rounded-full ${
                  currentSlide === index ? 'bg-[#F6F6F6] !w-[17px]' : 'bg-[#505668]'
                }`}
              ></button>
            ))}
          </div>
        </div>
      </div>
    </main>
  )
}
