/* eslint-disable */
import React, { useState } from 'react'
import { Table, TableHeader, TableRow, TableBody, TableCell, TableHead } from '../ui/table'
import { Select } from '@mui/material'
import { ArrowRight, ChevronRight, PiggyBank } from 'lucide-react'
import useMediaQuery from '../../hooks/useMediaQuery'
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '../ui/card'
import { Button } from '../ui/button'
import { Link, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import env from '../../env/env'
import { useModal } from '../../hooks/use-modal-store'

export default function MFIndividualInvestmentsTable({ holdings }) {
  const { onOpen } = useModal()
  const [isHovered, setIsHovered] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const navigate = useNavigate()

  const processFundName = (fund) => {
    const fundName = fund?.compname || ''

    if (fundName.includes('#')) {
      return fundName.split('#')[1].replace(/FUND.*$/, 'FUND')
    } else if (fundName.includes('.') || fundName.includes(',')) {
      const delimiter = fundName.includes('.') ? '.' : ','
      return fundName.split(delimiter)[0]
    }
    return fundName // Return as is if no delimiter is found
  }

  return (
    <>
      <div className='mt-12 max-w-[1128px] mx-auto hidden md:block'>
        {holdings?.mainData?.length > 0 ? (
          <Table className='w-full table-auto border-collapse rounded-lg shadow-lg'>
            <TableHeader className='bg-[#F4F7FE]'>
              <TableRow className='border-none'>
                <TableHead className='text-primary-400 py-3 h-0 w-10 rounded-l-xl'></TableHead>
                <TableHead className='text-primary-400 py-3 h-0 w-[50%]'>Fund Name</TableHead>
                {/* <TableHead className='text-primary-400 py-3 h-0 px-4 text-right'>
                    Current
                  </TableHead>
                  <TableHead className='text-primary-400 py-3 h-0 px-4 text-right'>
                    Invested
                  </TableHead>
                  <TableHead className='text-primary-400 py-3 h-0 rounded-r-xl px-4 text-right pr-[76px]'>
                    Returns
                  </TableHead> */}

                <TableHead className='text-primary-400 py-3 h-0 px-4 text-right'>Current</TableHead>

                <TableHead className='text-primary-400 py-3 h-0 px-4 text-right rounded-r-xl'>
                  Quantitiy
                </TableHead>
                {/* <TableHead className='text-primary-400 py-3 h-0 rounded-r-xl px-4 text-right pr-[76px]'>
                    Returns
                  </TableHead> */}
              </TableRow>
            </TableHeader>

            <TableBody>
              {holdings?.mainData?.map((fund, index) => (
                <TableRow
                  key={index}
                  className='cursor-pointer hover:shadow-[0px_24px_24px_-20px_rgba(0,0,0,0.08)]'
                  onMouseEnter={() => {
                    setIsHovered(true), setCurrentIndex(index)
                  }}
                  onMouseLeave={() => {
                    setIsHovered(false), setCurrentIndex(null)
                  }}
                >
                  <TableCell className='p-0 w-10'>
                    <img
                      src={`${env.MF_LOGOS_URL}/${fund.amcCode}.png`}
                      alt={`logo.svg`}
                      className='h-10 w-10'
                    />
                  </TableCell>
                  <TableCell className='px-4 py-4 flex items-center'>
                    <div className='w-full'>
                      <div className='flex justify-between gap-x-2'>
                        <div>
                          <h3 className='font-semibold'>
                            {fund?.legalName
                              ? fund?.legalName.replace(/(Fund).*/, '$1')
                              : fund?.schemeName.replace(/(Fund).*/, '$1')}
                          </h3>
                          <p className='text-xs text-black-400'> NAV ₹{fund?.closerate}</p>
                        </div>
                        <ChevronRight className='h-5 w-5 text-black-200 md:hidden' />
                      </div>

                      <div className='flex justify-between items-center lg:block mt-[2px]'>
                        {/* <p
                          className={`text-xs ${
                            fund.compname === 'Canara Robeco Small Cap Fund Direct Growth'
                              ? 'text-red-600'
                              : 'text-black-400'
                          }`}
                        >
                          SIP ₹{fund.holding.toLocaleString('en-IN')}
                        </p> */}

                        <div className='w-full flex gap-x-2 justify-between items-center text-sm font-medium md:hidden'>
                          <span> QTY {fund.balance}</span>
                          <span> ₹{fund.holding.toLocaleString('en-IN')}</span>

                          {/* <p className={`${fund.holding >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                            {fund.holding >= 0 ? '+' : ''} {fund.holding}%
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </TableCell>

                  <>
                    {/* <TableCell className='px-4 py-4 font-semibold text-right'>
                        {fund.balance.toLocaleString('en-IN')}
                      </TableCell> */}
                    <TableCell className='px-4 py-4 font-semibold text-right'>
                      ₹{fund.holding.toLocaleString('en-IN')}
                    </TableCell>
                    <TableCell className='px-4 py-4 font-semibold text-right'>
                      <button
                        className='relative min-w-[200px] overflow-hidden font-medium py-2 px-8 transition-all duration-300 ease-in-out cursor-pointer'
                        // className='bg-error-500 text-white'

                        // Fixed width to accommodate both texts
                        onClick={() =>
                          navigate('/dashboard/mutual-funds/redeem/place-order', {
                            state: {
                              fundDetails: fund,
                            },
                          })
                        }
                      >
                        <motion.span
                          className='inline-block'
                          animate={{ x: isHovered && currentIndex === index ? -40 : 60 }}
                          transition={{ type: 'tween', duration: 0.2 }}
                        >
                          {fund.balance}
                          {/* <div
                              className={`mt-1 text-xs font-medium ${
                                fund.balance >= 0 ? 'text-green-600' : 'text-red-600'
                              }`}
                            >
                              {fund.balance >= 0 ? '+' : ''}
                              {fund.balance}%
                            </div> */}
                        </motion.span>
                        <motion.div
                          className='absolute inset-y-0 right-0 flex items-center justify-center border bg-error-500 text-white rounded-lg font-bold px-4'
                          initial={{ x: '100%' }}
                          animate={{ x: isHovered && currentIndex === index ? 0 : '100%' }}
                          transition={{ type: 'tween', duration: 0.2 }}
                          style={{ width: '80px' }}
                        >
                          Redeem
                        </motion.div>
                        {/* <motion.div
                            className='absolute inset-y-0 right-0 flex items-center justify-center border text-primary-500 rounded-lg font-bold px-4'
                            initial={{ x: '100%' }}
                            animate={{ x: isHovered && currentIndex === index ? 0 : '100%' }}
                            transition={{ type: 'tween', duration: 0.2 }}
                            style={{ width: '80px' }}
                          >
                            Redeem
                          </motion.div> */}
                      </button>
                    </TableCell>
                  </>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className='bg-background flex items-center justify-center p-4'>
            <Card className='w-full max-w-md shadow-none border-none'>
              <CardHeader className='text-center'>
                <PiggyBank className='w-12 h-12 mx-auto mb-4 text-primary' />
                <CardTitle className='text-2xl font-semibold'>
                  Start Your Investment Journey
                </CardTitle>
              </CardHeader>
              <CardContent className='text-center space-y-2'>
                <p className='text-black-300'>You haven't invested in any mutual funds yet.</p>
                <p className='text-black-300'>
                  Mutual funds are a great way to start building your wealth.
                </p>
              </CardContent>
              <CardFooter className='flex justify-center'>
                <Link to='/dashboard/mutual-funds'>
                  <Button className='w-full'>
                    Explore Mutual Funds
                    <ArrowRight className='ml-2 h-4 w-4' />
                  </Button>
                </Link>
              </CardFooter>
            </Card>
          </div>
        )}
      </div>

      <div className='mt-12 max-w-[1128px] mx-auto md:hidden'>
        {holdings?.mainData?.length > 0 ? (
          <Table className='w-full table-auto border-collapse rounded-lg shadow-lg'>
            <TableBody>
              {holdings?.mainData?.map((fund, index) => (
                <TableRow
                  key={index}
                  className='cursor-pointer hover:shadow-[0px_24px_24px_-20px_rgba(0,0,0,0.08)]'
                  onMouseEnter={() => {
                    setIsHovered(true), setCurrentIndex(index)
                  }}
                  onMouseLeave={() => {
                    setIsHovered(false), setCurrentIndex(null)
                  }}
                  onClick={() => onOpen('openSellRequestForm', { fund })}
                >
                  <TableCell className='p-0 w-10'>
                    <img
                      src={`${env.MF_LOGOS_URL}/${fund.amcCode}.png`}
                      alt={`logo.svg`}
                      className='h-10 w-10'
                    />
                  </TableCell>
                  <TableCell className='px-4 py-4 flex items-center'>
                    <div className='w-full'>
                      <div className='flex justify-between gap-x-2'>
                        <div>
                          <h3 className='font-semibold'>{fund?.compname}</h3>
                          <p className='text-xs text-black-400 font-semibold'>
                            {fund.compname.split('#')[0]}
                          </p>
                        </div>
                        <ChevronRight className='h-5 w-5 text-black-200 md:hidden' />
                      </div>

                      <div className='flex justify-between items-center lg:block mt-[2px]'>
                        {/* <p
                          className={`text-xs ${
                            fund.compname === 'Canara Robeco Small Cap Fund Direct Growth'
                              ? 'text-red-600'
                              : 'text-black-400'
                          }`}
                        >
                          SIP ₹{fund.holding.toLocaleString('en-IN')}
                        </p> */}

                        <div className='w-full flex gap-x-2 justify-between items-center md:hidden'>
                          <span className='text-xs text-black-400'> QTY {fund.balance}</span>
                          <span className='font-semibold'>
                            ₹{fund.holding.toLocaleString('en-IN')}
                          </span>

                          {/* <p className={`${fund.holding >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                            {fund.holding >= 0 ? '+' : ''} {fund.holding}%
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className='bg-background flex items-center justify-center p-4'>
            <Card className='w-full max-w-md shadow-none border-none'>
              <CardHeader className='text-center'>
                <PiggyBank className='w-12 h-12 mx-auto mb-4 text-primary' />
                <CardTitle className='text-2xl font-semibold'>
                  Start Your Investment Journey
                </CardTitle>
              </CardHeader>
              <CardContent className='text-center space-y-2'>
                <p className='text-black-300'>You haven't invested in any mutual funds yet.</p>
                <p className='text-black-300'>
                  Mutual funds are a great way to start building your wealth.
                </p>
              </CardContent>
              <CardFooter className='flex justify-center'>
                <Link to='/dashboard/mutual-funds'>
                  <Button className='w-full'>
                    Explore Mutual Funds
                    <ArrowRight className='ml-2 h-4 w-4' />
                  </Button>
                </Link>
              </CardFooter>
            </Card>
          </div>
        )}
      </div>
    </>
  )
}
